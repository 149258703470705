require('dotenv').config();
import React from 'react';
import logoVelho from '../../assets/images/logo.png';
import logoBebidas from '../../assets/images/logo-bebidas-circulares.png';
import logoLoop from '../../assets/images/logo-loop.png';
import Footer from '../components/Footer';
import useGlobal from '../store';



const LoadingScreen = (props) => {
  const [globalState, globalActions] = useGlobal();
  return (
    <div className="loading-screen" id="loading-screen">
      <div className="loading-screen__logo">
        <img
          className={'loading-screen__image'}
          src={
            globalState.tenant == 'bebidasmaiscirculares'
              ? logoBebidas
              : logoVelho
          }
          alt="logo"
        />
      </div>
      <div className="loading-screen__loop">
        <div className="loading-screen__loop-content">
          <div className="loading-screen__made-by">built by</div>
          <div className="loading-screen__loop-image">
            <img src={logoLoop} className="loading-screen__logo-loop" />
          </div>
        </div>
      </div>
      <div className='loading-screen__footer'>
        <Footer />
      </div>
    </div>
  );
};

export default LoadingScreen;
