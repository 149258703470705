import React from 'react';
import PropTypes from 'prop-types';
import PointsAndPackages from './PointsAndPackages';
import Map from './Map';
import Scan from './Scan';
import Store from './Store';

const Card = (props) => {
  return (
    <div
      onLoad={props.load}
      onClick={props.click}
      className={props.class}
      id={props.identifier}
      ref={props.innerRef}>
      {props.layout}
    </div>
  );
};

export default Card;
