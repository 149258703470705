import React from 'react';
import useGlobal from '../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import coin from '../../assets/images/coin.png';

const PointsAndPackages = () => {
  const [globalState, globalActions] = useGlobal();
  const navigate = useHistory();

  const navigateToHistory = () => {
    navigate.push('/application/transactions');
  };

  return (
    <div className="points">
      <div className="points__points-section">
        <span className="points__points-title">Saldo atual: </span>
        <b className="points__value">
          {globalState.dashboard.current_balance} €
        </b>
      </div>
      <div className="points__historic-section" onClick={navigateToHistory}>
        <FontAwesomeIcon icon={faFileAlt} className="points__historic-icon" />
      </div>
    </div>
  );
};

export default PointsAndPackages;
