import React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-tiger-transition/styles/main.min.css';

import { Navigation, Route, Screen, Link, glide } from 'react-tiger-transition';
import logo from '../../assets/images/logo.png';
import logoBebidas from '../../assets/images/logo-bebidas-circulares.png';
import useGlobal from '../store';
import Button from './Button';
require('dotenv').config();

const Navbar = () => {
  const [globalState, globalActions] = useGlobal();
  const navigate = useHistory();

  const navigatePage = () => {
    globalState.tenant === 'bebidasmaiscirculares'
      ? window.open('https://www.bebidascirculares.pt/')
      : window.open('https://dovelhosefaznovo.pt/');
  };

  if (globalState.user.email == null) {
    return (
      <div className="navbar">
        <img
          src={
            globalState.tenant == 'bebidasmaiscirculares' ? logoBebidas : logo
          }
          alt="logo"
          className={'navbar__image'}
          // onClick={navigatePage}
        />

        <Link to="/application/login" className={'button button--tertiary'}>
          Entrar
        </Link>
      </div>
    );
  } else {
    return (
      <div className="navbar">
        <img
          src={
            globalState.tenant == 'bebidasmaiscirculares' ? logoBebidas : logo
          }
          alt="logo"
          className={'navbar__image'}
          // onClick={navigatePage}
        />
        <Link to="/application/profile" className={'button--tertiary'}>
          Perfil
        </Link>
      </div>
    );
  }
};

export default Navbar;
