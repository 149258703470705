import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormInput from '../components/FormInput';
import Button from '../components/Button';
import queryString from 'query-string';
import useGlobal from '../store';
import axios from 'axios';
import {isPasswordValid, ifBothPasswordsValid} from '../validators/validators';
import { useHistory } from 'react-router-dom';

const Password = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [reset_password_token, setToken] = useState('');
  const [globalState, globalActions] = useGlobal();
  const navigate = useHistory();
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [isShortPassword, setIsShortPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState('');

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);

    if (Object.keys(urlParams).length > 0) {
      setToken(urlParams?.reset_password_token);
    }
  }, []);

  const submitHandler = () => {
    let data = new FormData();
    data.append('spree_user[password]', newPassword);
    data.append('spree_user[password_confirmation]', confirmNewPassword);
    data.append('spree_user[reset_password_token]', reset_password_token);

    axios
      .put('/password/change', data, {
        headers: {
          'Content-Type': 'application/javascript',
          Accept: 'application/javascript',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status == 200) {
          globalActions.updateResponse(res.data.response);
          navigate.push(`/application/login`);
          props.message('A sua palavra-passe foi alterada com sucesso');
          props.messageType(true);
          props.modal(true);
        } 
      })
      .catch(function (error) {
        props.message(error.response.data.response.error.message);
        props.messageType(false);
        props.modal(true);
      });
  };

  const submitUnlock = () => {
    if (
      newPassword != '' &&
      confirmNewPassword != '' &&
      errorPassword == '' &&
      errorConfirmPassword == ''
    )
      return true;
    else return false;
  };

  return (
    <div className="card">
      <div className="register__title"> Recuperação da Palavra-Passe </div>
      <FormInput
        label={'Nova Palavra-Passe'}
        type={'password'}
        onchange={(e) => {
          setNewPassword(e.target.value);
          if (e.target.value == '') {
            setErrorPassword('');
            setIsShortPassword(false);
          } else if (e.target.value.length < 6) {
            setErrorPassword(
              'A password deverá ter no mínimo 6 caracteres'
            );
            if(confirmNewPassword != ""){
              if(e.target.value == confirmNewPassword){
                setErrorConfirmPassword('');
              }else{
                setErrorConfirmPassword('Passwords não correspondem');
              }
            }
            setIsShortPassword(true);
          } else {
            setErrorPassword('');
            setIsShortPassword(false);
            if(confirmNewPassword != ""){
              if(e.target.value == confirmNewPassword){
              setErrorConfirmPassword('');
            }else{
              setErrorConfirmPassword('Passwords não correspondem');
            }}
          }
        }}
        value={newPassword}
        name={'spree_user[password]'}
        placeholder={'Introduza a sua nova Palavra-Passe'}
        checker={isPasswordValid(newPassword, confirmNewPassword)}
        errorText={errorPassword}
        needValidation={true}
      />
      <FormInput
        label={'Confirmar nova Palavra-Passe'}
        onchange={(e) => {
          setConfirmNewPassword(e.target.value);
          if (e.target.value == '') {
            setErrorConfirmPassword('');
          } else if (isShortPassword) {
            setErrorConfirmPassword('');
          } else if (
            !ifBothPasswordsValid(newPassword, e.target.value)
          ) {
            setErrorConfirmPassword('Passwords não correspondem');
          } else {
            setErrorConfirmPassword('');
          }
        }}
        type={'password'}
        value={confirmNewPassword}
        name={'spree_user[password_confirmation]'}
        placeholder={'Confirme a sua nova Palavra-Passe'}
        checker={ifBothPasswordsValid(newPassword, confirmNewPassword)}
        errorText={errorConfirmPassword}
        needValidation={true}
      />
      <Button
        onclick={() => (submitUnlock() ? submitHandler() : null)}
        className={
          submitUnlock() ? 'button--primary' : 'button--primary-disabled'
        }
        text={'Submeter'}
      />
    </div>
  );
};

export default Password;
