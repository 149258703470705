import styled from 'styled-components';

export const ModalAreaTop = styled.div`
  backdrop-filter: blur(5.5px) !important;
  -webkit-backdrop-filter: blur(5.5px) !important;

  height: fit-content !important;
  width: 100%;
  padding: 24px 24px 24px 24px;

  background: ${(props) =>
    props.type === 'error'
      ? '#166B2A !important'
      : props.type === 'success'
      ? '#FF0016 !important'
      : 'rgba(255, 255, 255, 0.8) !important'};

  border-radius: 0px 0px 20px 20px !important;

  /* background: linear-gradient(#297b6d 0, #297b6d 80%) !important; */

  @media (min-width: 540px) {
    margin-left: calc(50vw - 270px);
    margin-right: calc(50vw - 270px);
  }
  @media (max-width: 540px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const ModalArea = styled.div`
  backdrop-filter: blur(5.5px) !important;
  -webkit-backdrop-filter: blur(5.5px) !important;

  height: fit-content !important;
  width: 100%;
  padding: 12px 24px 24px 24px;

  background: rgba(255, 255, 255, 0.8) !important;

  border-radius: 20px 20px 0px 0px !important;

  /* background: linear-gradient(#297b6d 0, #297b6d 80%) !important; */

  @media (min-width: 540px) {
    margin-left: calc(50vw - 270px);
    margin-right: calc(50vw - 270px);
  }
  @media (max-width: 540px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const ModalContent = styled.div`
  height: fit-content !important;
  max-width: 540px;
`;

export const CloseContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 4px;
`;

export const CloseButton = styled.label`
  color: #202020;
  font-size: 14;
  font-weight: 500;
  opacity: 0.2;
`;
