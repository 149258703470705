import React, { Component, useEffect, useState } from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
  return (
    <div>
      <Select
        options={props.locals.map((m) => ({ value: m.name, label: m.name }))}
      />
    </div>
  );
};

export default CustomSelect;
