import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import useGlobal from '../store';
import Button from '../components/Button';
import FormInput from '../components/FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  isValidEmail,
  isPhoneValid,
  isPasswordValid,
  ifBothPasswordsValid,
  isIbanValid,
} from '../validators/validators';
import TermsModal from '../components/TermsModal';

const Profile = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cc, setCc] = useState('');
  const [barcodes, setBarcodes] = useState('');
  const [iban, setIban] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPhoneMessage, setErrorPhoneMessage] = useState('');
  const [errorIbanMessage, setErrorIbanMessage] = useState('');
  const [isShortPassword, setIsShortPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setName(globalState.user.name);
    setEmail(globalState.user.email);
    setPhone(globalState.user.phone);
    setIban(globalState.user.iban);
    setCc(globalState.user.identification_document);
    setBarcodes(globalState.user.barcodes)
  }, []);

  let navigate = useHistory();

  const submitHandler = () => {
    let data = new FormData();
    data.append('citizen[name]', name);
    data.append('citizen[email]', email);
    data.append('citizen[phone]', phone);
    data.append('citizen[iban]', iban);
    data.append('citizen[identification_document]', cc);
    data.append('citizen[password]', password);
    data.append('citizen[password_confirmation]', passwordConfirm);

    axios
      .post('/update_citizen/' + globalState.user.id, data, {
        headers: {
          'Content-Type': 'application/javascript',
          Accept: 'application/javascript',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status == 200) {
          globalActions.updateResponse(res.data.response);
          navigate.push('/application/dashboard');
          props.message('O seu perfil foi alterado com sucesso.');
          props.messageType(true);
          props.modal(true);
        }
      })
      .catch(function (error) {
        props.message(error.response.data.response.error.message);
        props.messageType(false);
        props.modal(true);
      });
    console.log(data);
  };

  const logoutHandler = () => {
    axios
      .delete('/logout', {
        headers: {
          'Content-Type': 'application/javascript',
          Accept: 'application/javascript',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status == 200) {
          globalActions.updateResponse(res.data.response);
          navigate.push('/application/dashboard');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateEditing = () => {
    if (
      name == globalState.user.name &&
      email == globalState.user.email &&
      phone == globalState.user.phone &&
      iban == globalState.user.iban &&
      password == '' &&
      passwordConfirm == ''
    )
      setIsEditing(!isEditing);
    else {
      props.message('Alterações no perfil não guardadas');
      props.messageType(true);
      props.modal(true);
      setName(globalState.user.name);
      setEmail(globalState.user.email);
      setPhone(globalState.user.phone);
      setIban(globalState.user.iban);
      setPassword('');
      setPasswordConfirm('');
      setErrorEmailMessage('');
      setErrorConfirmPassword('');
      setErrorPassword('');
      setErrorPhoneMessage('');
      setErrorIbanMessage('');
      setIsShortPassword('');
      setIsEditing(!isEditing);
    }
  };

  const navigateToDashboard = () => {
    navigate.push('/application/dashboard');
  };

  const submitUnlock = () => {
    if (password != '' && passwordConfirm == '') return false;
    else if (
      email != '' &&
      errorEmailMessage == '' &&
      errorPhoneMessage == '' &&
      errorPassword == '' &&
      errorIbanMessage == '' &&
      errorConfirmPassword == ''
    )
      return true;
    else return false;
  };

  const openTermsModal = () => {
    setModalOpen(true);
  };

  const navigatePdf = () => {
    window.open('https://dovelhosefaznovo.pt/doc/RVM_Regulamento.pdf');
  };

  const navigatePdfCircular = () => {
    window.open(
      'https://www.bebidascirculares.pt/wp-content/uploads/2022/01/RVM_Regulamento_BC_17.01.2022.pdf'
    );
  };

  const circularPolicy = () => {
    window.open('https://www.bebidascirculares.pt/politica-de-privacidade/');
  };

  return (
    <div className="card profile">
      <div className="profile__header">
        <div className="card__top-wrapper">
          <Button
            onclick={navigateToDashboard}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faArrowLeft} size="xs" />}
          />
          <div className="card__title"> {'Perfil  '} </div>
        </div>
        <div className="profile__edit">
          {!isEditing ? (
            <div className="profile__edit-button" onClick={updateEditing}>
              <span> Editar perfil </span>
              <FontAwesomeIcon icon={faPencilAlt} size="sm" />
            </div>
          ) : (
            <div className="profile__edit-button" onClick={updateEditing}>
              <span> Cancelar </span>
              <FontAwesomeIcon icon={faTimes} size="sm" />
            </div>
          )}
        </div>
      </div>
      <div className="profile__content">
        <div className="profile__field-input">
          <div className="profile__section">
            {!isEditing ? (
              <div className="profile__section-column">
                <label className="form-input__label"> Nome </label>
                <span className="profile__text"> {name}</span>
              </div>
            ) : (
              <FormInput
                label={'Nome'}
                type={'text'}
                onchange={(e) => setName(e.target.value)}
                value={name}
                name={'citizen[name]'}
                placeholder={'Nome'}
                needValidation={false}
                autoComplete="username"
              />
            )}
          </div>
          <div className="profile__section">
            {!isEditing ? (
              <div className="profile__section-column">
                <label className="form-input__label">
                  {' '}
                  Número de Telemóvel (opcional)
                </label>
                <span className="profile__text"> {phone} </span>
              </div>
            ) : (
              <FormInput
                label={'Número de Telemóvel (opcional)'}
                onchange={(e) => {
                  setPhone(e.target.value);
                  if (e.target.value == '') {
                    setErrorPhoneMessage('');
                  } else if (!isPhoneValid(e.target.value)) {
                    setErrorPhoneMessage('Número Invalido');
                  } else {
                    setErrorPhoneMessage('');
                  }
                }}
                value={phone}
                type={'tel'}
                name={'phone'}
                checker={isPhoneValid(phone)}
                placeholder={'Número de Telemóvel (opcional)'}
                needValidation={true}
                errorText={errorPhoneMessage}
                autoComplete="phone"
              />
            )}
          </div>
          <div className="profile__section">
            {!isEditing ? (
              <div className="profile__section-column">
                <label className="form-input__label"> IBAN (recomendado)</label>
                <span className="profile__text"> {iban} </span>
              </div>
            ) : (
              <FormInput
                label={'IBAN (recomendado)'}
                onchange={(e) => {
                  setIban(e.target.value);
                  if (e.target.value == '') {
                    setErrorIbanMessage('');
                  } else if (!isIbanValid(e.target.value)) {
                    setErrorIbanMessage('Iban Inválido');
                  } else {
                    setErrorIbanMessage('');
                  }
                }}
                value={iban}
                type={'text'}
                name={'IBAN'}
                checker={isIbanValid(iban)}
                placeholder={'IBAN (recomendado)'}
                needValidation={true}
                errorText={errorIbanMessage}
              />
            )}
          </div>
          <div className="profile__section">
            <div className="profile__section-column">
              <label className="form-input__label"> E-mail </label>
              <span className="profile__text"> {email} </span>
            </div>
          </div>
          <div className="profile__section">
            <div className="profile__section-column">
              <label className="form-input__label">
                {' '}
                Número de Cartão de Cidadão
              </label>
              <span className="profile__text"> {cc} </span>
            </div>
          </div>
          <div className="profile__section">
            <div className="profile__section-column">
              <label className="form-input__label">
                {' '}
                Código de Barras dos Cartões
              </label>
              <span className="profile__text"> {barcodes} </span>
            </div>
          </div>
          <div className="profile__section">
            {isEditing && (
              <>
                <FormInput
                  label={'Nova Palavra-Passe'}
                  onchange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value == '') {
                      setErrorPassword('');
                      setIsShortPassword(false);
                    } else if (e.target.value.length < 6) {
                      setErrorPassword(
                        'A password deverá ter no mínimo 6 caracteres'
                      );
                      if (passwordConfirm != '') {
                        if (e.target.value == passwordConfirm) {
                          setErrorConfirmPassword('');
                        } else {
                          setErrorConfirmPassword('Passwords não correspondem');
                        }
                      }
                      setIsShortPassword(true);
                    } else {
                      setErrorPassword('');
                      setIsShortPassword(false);
                      if (passwordConfirm != '') {
                        if (e.target.value == passwordConfirm) {
                          setErrorConfirmPassword('');
                        } else {
                          setErrorConfirmPassword('Passwords não correspondem');
                        }
                      }
                    }
                  }}
                  value={password}
                  type={'password'}
                  name={'citizen[password]'}
                  placeholder={'Introduza a sua nova Palavra-Passe'}
                  checker={isPasswordValid(password, passwordConfirm)}
                  needValidation={true}
                  errorText={errorPassword}
                  autoComplete="off"
                />

                <FormInput
                  label={'Confirmar nova Palavra-Passe'}
                  onchange={(e) => {
                    setPasswordConfirm(e.target.value);
                    if (e.target.value == '') {
                      setErrorConfirmPassword('');
                    } else if (isShortPassword) {
                      setErrorConfirmPassword('');
                    } else if (
                      !ifBothPasswordsValid(password, e.target.value)
                    ) {
                      setErrorConfirmPassword('Passwords não correspondem');
                    } else {
                      setErrorConfirmPassword('');
                    }
                  }}
                  value={passwordConfirm}
                  type={'password'}
                  name={'citizen[password_confirmation]'}
                  placeholder={'Confirme a sua nova Palavra-Passe'}
                  checker={ifBothPasswordsValid(password, passwordConfirm)}
                  needValidation={true}
                  errorText={errorConfirmPassword}
                  autoComplete="off"
                />
              </>
            )}
          </div>
        </div>
        {/* <div className="profile__terms-field">
          {globalState.tenant === 'bebidasmaiscirculares' ? (
            <a className={'profile__terms'} onClick={navigatePdfCircular}>
              Termos e Condições
            </a>
          ) : (
            <a className={'profile__terms'} onClick={navigatePdf}>
              Termos e Condições
            </a>
          )}
          {globalState.tenant === 'bebidasmaiscirculares' ? (
            <a className={'profile__terms'} onClick={circularPolicy}>
              Política de Privacidade e Política de Cookies
            </a>
          ) : (
            <a className={'profile__terms'} onClick={openTermsModal}>
              Política de Privacidade e Política de Cookies
            </a>
          )}

          <TermsModal
            modalOpen={modalOpen}
            setModalOpen={() => setModalOpen(false)}
          />
        </div> */}
        <div className="profile__actions">
          {!isEditing ? (
            <Button
              onclick={logoutHandler}
              className={'button--secondary'}
              text={'Terminar Sessão'}
            />
          ) : (
            <>
              <Button
                onclick={() => (submitUnlock() ? submitHandler() : null)}
                className={
                  submitUnlock()
                    ? 'button--primary'
                    : 'button--primary-disabled'
                }
                text={'Guardar'}
              />
              <Button
                onclick={logoutHandler}
                className={'button--secondary'}
                text={'Terminar Sessão'}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
