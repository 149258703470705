require('dotenv').config();
import React, { Component } from 'react';
import Slider from 'react-slick';
import eeagrantsImage from '../../assets/images/eea_grants.png';
import rp from '../../assets/images/Rp.png';
import acores from '../../assets/images/acores.png';
import musami from '../../assets/images/logo musami.png';
import CM from '../../assets/images/LOGO CM 01.png';
import santaCruz from '../../assets/images/Logotipo Cores.png';
import lagoa from '../../assets/images/LagoaMunicipio_CMYK.png';
import VilaPorto from '../../assets/images/logo_v2.png';
import horta from '../../assets/images/logo_cmh_simplificado-01.png';
import fundoImage from '../../assets/images/fundo.png';
import calheta from '../../assets/images/BrasaoCalheta.png';
import corvo from '../../assets/images/BrasaoCorvo.png';
import flores from '../../assets/images/BrasaoLajesFlores.png';
import povoacao from '../../assets/images/BrasaoPovoacao.png';
import roque from '../../assets/images/BrasaoSRoquePico.png';
import velas from '../../assets/images/BrasaoVelas.png';
import madalena from '../../assets/images/BrasaoVilaMadalena.png';
import cmrg from '../../assets/images/CMRG.png';
import municipios from '../../assets/images/LogoAssociacaoMunicipios.png';
import cmah from '../../assets/images/LogoCMAH.png';
import cmlp from '../../assets/images/CMLP.png';
import cvfc from '../../assets/images/LogoCVFC.png';
import equi from '../../assets/images/LogoEquiambi.png';
import melo from '../../assets/images/LogoMeloAbreu.png';
import ativo from '../../assets/images/LogoNordesteAtivo.png';
import pico from '../../assets/images/LogoPicoWine.png';
import praia from '../../assets/images/LogoPraiaAmbiente.png';
import promineral from '../../assets/images/LogoPromineral.png';
import riac from '../../assets/images/LogoRiac1.png';
import simbr from '../../assets/images/simboloresiacores.png';

import useGlobal from '../store';
const Footer = (props) => {
  const [globalState, globalActions] = useGlobal();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: -1,
    cssEase: 'linear',
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: -1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <div className="footer__slider clearfix">
      <div className="footer__main">
        <img
          src={eeagrantsImage}
          alt="eea grants"
          className="footer__image--main"
        />
        <img
          src={acores}
          alt="eea grants"
          className="footer__image--main footer__image--last"
        />
      </div>

      <div>
        <Slider {...settings}>
          <div className="slick-track">
            <img src={VilaPorto} alt="vila" className="footer__image" />
          </div>
          <div>
            <img src={santaCruz} alt="santa" className="footer__image" />
          </div>
          <div>
            <img src={CM} alt="cm" className="footer__image" />
          </div>
          <div>
            <img src={musami} alt="musami" className="footer__image" />
          </div>
          <div>
            <img src={lagoa} alt="lagoa" className="footer__image" />
          </div>
          <div>
            <img src={horta} alt="horta" className="footer__image" />
          </div>
          <div>
            <img src={calheta} alt="calheta" className="footer__image" />
          </div>
          <div>
            <img src={flores} alt="flores" className="footer__image" />
          </div>
          <div>
            <img src={corvo} alt="corvo" className="footer__image" />
          </div>
          <div>
            <img src={povoacao} alt="povoacao" className="footer__image" />
          </div>
          <div>
            <img src={roque} alt="roque" className="footer__image" />
          </div>
          <div>
            <img src={velas} alt="velas" className="footer__image" />
          </div>
          <div>
            <img src={madalena} alt="madalena" className="footer__image" />
          </div>
          <div>
            <img src={cmrg} alt="cmrg" className="footer__image" />
          </div>
          <div>
            <img src={municipios} alt="municipios" className="footer__image" />
          </div>
          <div>
            <img src={cmah} alt="cmah" className="footer__image" />
          </div>
          <div>
            <img src={cmlp} alt="cmlp" className="footer__image" />
          </div>
          <div>
            <img src={cvfc} alt="cvfc" className="footer__image" />
          </div>
          <div>
            <img src={equi} alt="equi" className="footer__image" />
          </div>
          <div>
            <img src={melo} alt="melo" className="footer__image" />
          </div>
          <div>
            <img src={ativo} alt="ativo" className="footer__image" />
          </div>
          <div>
            <img src={pico} alt="pico" className="footer__image" />
          </div>
          <div>
            <img src={praia} alt="praia" className="footer__image" />
          </div>
          <div>
            <img src={promineral} alt="promineral" className="footer__image" />
          </div>
          <div>
            <img src={riac} alt="riac" className="footer__image" />
          </div>
          <div>
            <img src={simbr} alt="simbr" className="footer__image" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Footer;
