import React from 'react';
import {
  CustomSheet,
  CloseContainer,
  CloseButton,
} from './BottomSheet.styles.ts';

export const BottomSheet = (props) => {
  return (
    <>
      <CustomSheet
        isOpen={props.open}
        onClose={props.close}
        springConfig={{ stiffness: 300, damping: 30, mass: 0.2 }}>
        <CustomSheet.Container>
          {/* <CustomSheet.Header /> */}

          <CustomSheet.Content>
            <>
              <CloseContainer onClick={props.close}>
                <CloseButton>x</CloseButton>
              </CloseContainer>
              {props.content}
            </>
          </CustomSheet.Content>
        </CustomSheet.Container>

        {/* <CustomSheet.Backdrop /> */}
      </CustomSheet>
    </>
  );
};
