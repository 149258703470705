import React from 'react';

const Checkbox = (props) => {
  return (
    <div className="checkbox">
      <label className="checkbox__container">
        <input
          type="checkbox"
          onChange={props.onchange}
          value={props.value}
          name={props.name}
        />
        <span className="checkbox__checkmark"> </span>
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
