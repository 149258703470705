import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/browser';
import DecodeHintType from '@zxing/library/cjs/core/DecodeHintType';
import useGlobal from '../store';
require('dotenv').config();

const Scanner = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [scanSuccess, setScanSuccess] = useState(false);
  const video = useRef();
  let codeReader = null;
  let controls = null;

  useEffect(() => {
    return () => {
      console.log('UNMOUNT');
      console.log(controls);
      if (controls !== null) {
        controls.stop();
      }
    };
  }, []);

  useEffect(() => {
    const hints = new Map();
    const formats = [BarcodeFormat.CODE_128];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    const handleCodeReader = async () => {
      codeReader = await new BrowserMultiFormatReader(hints, {
        delayBetweenScanAttempts: 100,
        delayBetweenScanSuccess: 0,
      });
      controls = await codeReader.decodeFromVideoDevice(
        undefined,
        video.current,
        (result) => {
          console.log(result);
          if (
            globalState.tenant !== 'bebidasmaiscirculares' &&
            result &&
            result.text &&
            result.text.length === 28
          ) {
            controls.stop();
            props.handleSubmit(result.text, 'camera');
          }
        }
      );
    };
    handleCodeReader();
  }, []);

  return (
    <>
      <video
        id="video"
        ref={video}
        style={{ width: '100%', height: '100%' }}></video>
    </>
  );
};

export default Scanner;
