require('dotenv').config();
import React, { useState, useRef, useEffect } from 'react';
import Card from '../components/Card';
import Map from '../components/Map';
import Store from '../components/Store';
import PointsAndPackages from '../components/PointsAndPackages';
import Scan from '../components/Scan';
import $ from 'jquery';
import useGlobal from '../store';
import { useMediaQuery } from 'react-responsive';
import queryString from 'query-string';
import axios from 'axios';
import {
  OnScanErrorScreenEnter,
  OnScanScreenLeave,
  OnScanSuccessScreenEnter,
} from '../animations/animation';

import {
  OpenMapCard,
  CloseMapCard,
  OpenScanCard,
  CloseScanCard,
  OpenStoreCard,
  CloseStoreCard,
  CloseSuccessScanCard,
} from '../animations/animation';

const Dashboard = (props) => {
  const [globalState, globalActions] = useGlobal();
  const [scanCode, setScanCode] = useState('');
  const [blockMapButton, setBlockMapButton] = useState(false);
  const [scanBlockButton, setScanBlockButton] = useState(false);
  const [blockScanButton, setBlockScanButton] = useState(false);
  const [blockStoreButton, setBlockStoreButton] = useState(false);
  const [startCamera, setStartCamera] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submitHandler = (paramCode, type) => {
    console.log(paramCode + '      ' + type);
    setErrorMessage('');
    OnScanScreenLeave();
    axios
      .post(
        '/users/redeem_code',
        {
          code: paramCode
            ? paramCode.replaceAll(' ', '')
            : scanCode.replaceAll(' ', ''),
          method: type,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        globalActions.updateResponse(res.data.response);
        OnScanSuccessScreenEnter(scanBlockButton, setScanBlockButton);
        setScanCode('');
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response.data.response.error.message);
          OnScanErrorScreenEnter(scanBlockButton, setScanBlockButton);
        }
      });
  };

  useEffect(() => {
    if (!props.isLoading) {
      const urlParams = queryString.parse(window.location.search);
      if (Object.keys(urlParams).length > 0 && urlParams.code != null) {
        setScanCode(urlParams.code);
        onScanClick();
        setTimeout(() => {
          submitHandler(urlParams.code, 'codigo url');
        }, 2000);
      } else if (urlParams.open_store === 'true') {
        onStoreClick();
      }
    }
  }, [props.isLoading]);

  const card1 = useRef();
  const card2 = useRef();
  const card3 = useRef();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 480px)',
  });

  const [card1State, setCard1State] = useState(false);
  const [card2State, setCard2State] = useState(false);
  const [card3State, setCard3State] = useState(false);

  const onMapClick = () => {
    var f = !$('#card1').data('toggleFlag');

    if (!f) {
      setCard1State(false);
      CloseMapCard(blockMapButton, setBlockMapButton);
    } else {
      setCard1State(true);
      OpenMapCard(blockMapButton, setBlockMapButton);
    }
    $('#card1').data('toggleFlag', f);
  };

  const onScanClick = () => {
    var f = !$('#card2').data('toggleFlag');

    if (!f) {
      setScanCode('');
      setCard2State(false);
      CloseScanCard(blockScanButton, setBlockScanButton);
      setStartCamera(false);
    } else {
      setCard2State(true);
      OpenScanCard(blockScanButton, setBlockScanButton);
      setStartCamera(true);
    }
    $('#card2').data('toggleFlag', f);
  };

  const onSuccessScanClick = () => {
    var f = !$('#card2').data('toggleFlag');
    setStartCamera(false);

    if (!f) {
      setScanCode('');
      setCard2State(false);
      CloseSuccessScanCard(blockScanButton, setBlockScanButton);
    }
    $('#card2').data('toggleFlag', f);
  };

  const onStoreClick = () => {
    var f = !$('#card3').data('toggleFlag');

    if (!f) {
      setCard3State(false);
      CloseStoreCard(blockStoreButton, setBlockStoreButton);
    } else {
      setCard3State(true);
      OpenStoreCard(blockStoreButton, setBlockStoreButton);
    }
    $('#card3').data('toggleFlag', f);
  };

  return (
    <div className={'dashboard'}>
      {globalState.user.email != null && (
        <Card layout={<PointsAndPackages />} class={'card'} />
      )}
      <div
        className={
          globalState.user.email != null
            ? 'dashboard__container dashboard__container--min'
            : 'dashboard__container dashboard__container--max'
        }>
        <div className="map-card" ref={card3} id="card1">
          <Card
            layout={
              <Map
                blockedButton={blockMapButton}
                state={card1State}
                click={onMapClick}
              />
            }
            class={'animated-card map-animated-card card'}
            innerRef={card1}
          />
        </div>
        <div className="scan-card" ref={card3} id="card2">
          <Card
            layout={
              <Scan
                state={card2State}
                click={onScanClick}
                setCode={setScanCode}
                code={scanCode}
                blockedButton={blockScanButton}
                scanBlockButton={scanBlockButton}
                setScanBlockButton={setScanBlockButton}
                successClick={onSuccessScanClick}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                startCamera={startCamera}
                submitHandler={submitHandler}
              />
            }
            class={'animated-card scan-animated-card card'}
            innerRef={card2}
          />
        </div>
        {globalState.user.email != null && (
          <div className="store-card" ref={card3} id="card3">
            <Card
              layout={
                <Store
                  blockedButton={blockStoreButton}
                  state={card3State}
                  click={onStoreClick}
                />
              }
              class={'animated-card card store-animated-card card--no-margin'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
