import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import useGlobal from '../store';
import queryString from 'query-string';
import FormInput from '../components/FormInput';
import { isValidEmail } from '../validators/validators';
import Button from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [globalState, globalActions] = useGlobal();
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [scanMessage, setscanMessage] = useState('');
  const navigate = useHistory();

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);

    if (Object.keys(urlParams).length > 0) {
      setCode(urlParams?.code);
      //mudar para a mensagem desejada
      setscanMessage(
        'Entre na sua conta ou registe-se para receber os seus pontos.'
      );
    }
  }, []);

  const navigateToRegister = () => {
    if (code) {
      navigate.push(`/application/registration?code=${code}`);
    } else {
      navigate.push('/application/registration');
    }
  };

  const submitHandler = () => {
    let data = new FormData();
    data.append('spree_user[email]', email);
    data.append('spree_user[password]', password);

    axios
      .post('/login', data, {
        headers: {
          'Content-Type': 'application/javascript',
          Accept: 'application/javascript',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status == 200) {
          globalActions.updateResponse(res.data.response);
          if (code) {
            navigate.push(`/application/dashboard?code=${code}`);
            props.message('');
            props.messageType(false);
            props.modal(false);
          } else { 
            navigate.push(`/application/dashboard`);
            props.message('Olá ' + res.data.response.user.name + '! Bem vindo às Embalagens com Valor Açores!');
            props.messageType(true);
            props.modal(true);
          }
        }
      })
      .catch(function (error) {
        setErrorMessage(error.response.data.response.error.message);
        props.message(error.response.data.response.error.message);
        props.messageType(false);
        props.modal(true);
      });
  };

  const navigateToEmail = () => {
    navigate.push('/application/email');
  };

  const navigateToSetPassword = () => {
    navigate.push('/application/set_password');
  };

  const navigateToDashboard = () => {
    navigate.push('/application/dashboard');
  };

  return (
    <div className="login card">
      <div className="card__top-wrapper">
        <Button
          onclick={navigateToDashboard}
          className={'button button--back'}
          text={<FontAwesomeIcon icon={faArrowLeft} />}
        />
        <div className="card__title"> {'Entrar'} </div>
      </div>
      <div className="login__fields">
        {/* {errorMessage && errorMessage !== '' && (
          <div className="login__error-message">{errorMessage}</div>
        )} */}
        <div className="login__message">{scanMessage}</div>
        <div id="password-credentials">
          <FormInput
            label={'E-mail'}
            onchange={(e) => setEmail(e.target.value)}
            value={email}
            type={'email'}
            name={'spree_user[email]'}
            checker={isValidEmail(email)}
            placeholder={'Introduza o seu e-mail'}
            needValidation={true}
          />

          <FormInput
            label={'Palavra-Passe'}
            onchange={(e) => setPassword(e.target.value)}
            value={password}
            type={'password'}
            name={'spree_user[password]'}
            placeholder={'Introduza a sua Palavra-Passe'}
            needValidation={false}
          />
        </div>
        <div className={'login__forgot-password'} onClick={navigateToEmail}>
          Esqueci-me da minha palavra-passe
        </div>
        <div className={'login__forgot-password'} onClick={navigateToSetPassword}>
          Não recebi email de definição de palavra-passe
        </div>
      </div>
      <div className="login__actions">
        <Button
          onclick={submitHandler}
          className={'button--primary'}
          text={'Entrar'}
        />
        {/* <Button
          onclick={navigateToRegister}
          className="button--secondary"
          text={'Novo Registo'}
        /> */}
      </div>
    </div>
  );
};

export default Login;
