import styled from 'styled-components';
import Sheet from 'react-modal-sheet';

export const CustomSheet = styled(Sheet)`
  .react-modal-sheet-container {
    height: fit-content !important;
    max-width: 900px;

    background: rgba(255, 255, 255, 0.8) !important;

    border-radius: 20px 20px 0 0 !important;

    @media (min-width: 540px) {
      margin-left: calc(50vw - 270px);
      margin-right: calc(50vw - 270px);
    }

    @media (min-width: 768px) {
      margin-left: calc(50vw - 385px);
      margin-right: calc(50vw - 385px);
    }

    @media (min-width: 900px) {
      margin-left: calc(50vw - 450px);
      margin-right: calc(50vw - 450px);
    }

    @media (max-width: 540px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .react-modal-sheet-header {
    background-color: transparent;
    border-bottom: 0.5px solid var(--color-border);
    border-radius: 20px 20px 0 0 !important;
  }
  .react-modal-sheet-drag-indicator {
    /* background-color: var(--color-background-opacity); */
  }
  .react-modal-sheet-content {
    padding: 12px 24px 24px 24px;
  }
  .react-modal-sheet-backdrop {
    /* custom styles */
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 4px;
`;

export const CloseButton = styled.label`
  color: #202020;
  font-size: 14;
  font-weight: 500;
  opacity: 0.2;
`;
