import React from "react"
import PropTypes from "prop-types"
class Transactions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      transactions: []
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    let data = new FormData(event.target)
    const res = await fetch("/account/transactions?" + new URLSearchParams(data).toString())
    const json = await res.json()

    this.setState({ transactions: json.transactions })
  }

  render() {

    const list = this.state.transactions.map(t =>
      <li key={t.id}> 
        { (new Date(t.created_at)).toLocaleDateString() } {t.amount} pontos {t.kind} {t.code}
      </li>
    )

    return (
      <div>
        <ul>
          {list}
        </ul>
        {this.state.transactions.length}

        <form onSubmit={this.handleSubmit}>
          <input type="submit" value="Submit" />
          <input type="date" name="start_date"></input>
          <input type="date" name="end_date"></input>
          <select name="kind">
            <option value="">None</option>
            <option value="redeem_code">Pontos redimidos</option>
            <option value="use_points">Pontos usados</option>
          </select>
        </form>
      </div>
    );
  }


}

export default Transactions
