import React from 'react';

const FormInput = (props) => {
  const validator = () => {
    if (props.needValidation) {
      if (props.value === '') {
        return 'form-input';
      } else if (props.value !== '' && props.checker) {
        return 'form-input form-input--is-valid';
      } else {
        return 'form-input form-input--is-invalid';
      }
    } else {
      return 'form-input';
    }
  };

  return (
    <div className={validator()}>
      <label className={'form-input__label'}>{props.label}</label>
      <input
        className={'form-input__input'}
        type={props.type}
        onChange={props.onchange}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
      />
      {props.errorText && props.errorText !== '' && (
        <span className="form-input__error">{props.errorText}</span>
      )}
    </div>
  );
};

export default FormInput;
