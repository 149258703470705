import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import useGlobal from '../store';
import coin from '../../assets/images/coin.png';

import water from '../../assets/images/water-bottle.png';
import cola from '../../assets/images/cola-bottle.png';
import can from '../../assets/images/cola-can.png';
import wallet from '../../assets/images/wallet.png';

const Transaction = () => {
  const [globalState, globalActions] = useGlobal();
  const navigate = useHistory();

  const month = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
  const d = new Date()

  const navigateToDashboard = () => {
    navigate.push('/application/dashboard');
  };

  const navigateToProfile = () => {
    navigate.push('/application/profile')
  }

  return (
    <div className="transaction">
      <div className="transaction__header">
        <div className="card__top-wrapper">
          <Button
            onclick={navigateToDashboard}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faArrowLeft} />}
          />
        </div>
      </div>
      <div className="transaction__content">
        <div className='card'>
          <p className='font-color-primary'>Quantas embalagens já entreguei?</p>
          <span className="transaction__bottles">
            <div className="transaction__bottle-wrapper transaction__bottle-wrapper--total">
              <img
                src={water}
                alt="pontos"
                className={'transaction__bottle-image margin-right-xxs'}
              />
              <span className='scan-initial-box__bottle-value'>{globalState.dashboard.pet}</span>
            </div>
            <div className="transaction__bottle-wrapper transaction__bottle-wrapper--total">
              <img
                src={cola}
                alt="pontos"
                className={'transaction__bottle-image margin-right-xxs'}
              />
              <span className='scan-initial-box__bottle-value'>{globalState.dashboard.glass}</span>
            </div>
            <div className="transaction__bottle-wrapper">
              <img
                src={can}
                alt="pontos"
                className={'transaction__bottle-image margin-right-xxs'}
              />
              <span className='scan-initial-box__bottle-value'>{globalState.dashboard.aluminium}</span>
            </div>
          </span>
        </div>
        <div className="transaction__wallet-wrapper">
          <div className="transaction__wallet-card card">
            <p>Valor por pagar</p>
            <p className="transaction__wallet-value">
              <b> {globalState.dashboard.current_balance} €</b>
            </p>            
          </div>
          <div className="transaction__wallet-card card">
            <p>Valor já pago</p>
            <p className="transaction__wallet-value">
              <b>{globalState.dashboard.redeemed_balance} €</b>
            </p>
          </div>
          <div className="transaction__wallet-warning card">
            <p>Total acumulado: <b>{globalState.dashboard.total_balance} €</b></p>
          </div>
          <div className="transaction__wallet-warning card">
              {((globalState.user.iban == null) || (globalState.user.iban == "")) ? (
                <span>
                  Transferências bancárias disponíveis apenas para contas com IBAN associado. <br></br><b onClick={navigateToProfile}>Preencha aqui o seu IBAN</b>. 
                </span>
              ) : (
                <span>
                  O seu <b>Saldo Creditado</b> será transferido através de transferência bancária periódica após atingir um mínimo de <b>3 €</b>
               </span>
              )
              }
            <span className='font-size-small'>Nota: Só serão creditadas até 500 embalagens por mês (25€)</span>
          </div>
          <div className="transaction__wallet-warning card">
            <p>A comunicação entre as máquinas e a plataforma não é imediata. Caso não encontre o seu depósito após 24h, entre em contacto através de embalagenscomvalor@azores.gov.pt ou do número 925 220 540.</p>
          </div>
        </div >
        {
          globalState.transactions.length !== 0 ? (
            globalState.transactions.map((transaction) => {
              if (transaction.kind === 'redeem_code') {
                return (
                  <div
                    className="transaction__info-section"
                    key={transaction.created_at}>
                    <div className="transaction__section">
                      <span className="transaction__store">
                        {' '}
                        {transaction.store} -
                      </span>
                      <span className="transaction__date">
                        {transaction.created_at}
                      </span>
                    </div>
                    <div className="transaction__info-main">
                      <span className="transaction__info-image-section">
                        <img
                          src={coin}
                          alt="pontos"
                          className={'transaction__info-image'}
                        />
                      </span>
                      <span className="transaction__points-label">
                        <b>
                          {' '}
                          {transaction.kind === 'redeem_code' ||
                            transaction.kind === 'redeem_code_correction'
                            ? '+'
                            : '-'}
                          {transaction.amount} €{' '}
                        </b>
                      </span>
                      <span className="transaction__info">
                        {' '}
                        <span className="transaction__bottles">
                          <div className="transaction__bottle-wrapper">
                            <img
                              src={water}
                              alt="pontos"
                              className={'transaction__bottle-image'}
                            />
                            <span>x{transaction.pet_packages}</span>
                          </div>
                          <div className="transaction__bottle-wrapper">
                            <img
                              src={cola}
                              alt="pontos"
                              className={'transaction__bottle-image'}
                            />
                            <span>x{transaction.glass_packages}</span>
                          </div>
                          <div className="transaction__bottle-wrapper">
                            <img
                              src={can}
                              alt="pontos"
                              className={'transaction__bottle-image'}
                            />
                            <span>x{transaction.aluminum_packages}</span>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="transaction__info-section"
                    key={transaction.created_at}>
                    <div className="transaction__section">
                      <span className="transaction__store">Pagamento -</span>
                      <span className="transaction__date">
                        {transaction.created_at}
                      </span>
                    </div>
                    <div className="transaction__info-main">
                      <span className="transaction__info-image-section">
                        <img
                          src={wallet}
                          alt="pontos"
                          className={
                            'transaction__info-image transaction__info-image--wallet'
                          }
                        />
                      </span>
                      <span className="transaction__points-label transaction__points-label--payment">
                        <b> {transaction.amount} € </b>
                      </span>
                    </div>
                    {/* <span className="transaction__iban">
                    IBAN: PT5020009000600070008000
                  </span> */}
                    {/* <div className="transaction__tag">{transaction.status}</div> */}
                  </div>
                );
              }
            })
          ) : (
            <span className="transaction__message">
              {' '}
              Ainda não existem transações.{' '}
            </span>
          )
        }
      </div >
    </div >
  );
};

export default Transaction;
