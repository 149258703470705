import { PROPERTY_TYPES } from '@babel/types';
import React from 'react';

const Button = (props) => {
  return (
    <button onClick={props.onclick} className={props.className}>
      {props.iconToLeft && <span>{props.icon} </span>}
      {props.text}
      {props.iconToRight && <span> {props.icon}</span>}
    </button>
  );
};

export default Button;
