import React, { createContext, useState, useRef } from 'react';

import ActionSheet, {
  ActionSheetProps,
  ActionSheetRef,
} from 'actionsheet-react';
import {
  ModalArea,
  ModalAreaTop,
  ModalContent,
  CloseContainer,
  CloseButton,
} from './ModalSheet.styles.ts';

const ModalSheetContext = createContext({
  handleOpen: () => {},
  handleOpenTop: () => {},
  handleClose: () => {},
  showModal: () => {},
});

const ModalSheetProvider = ({ children }) => {
  const sheetRef = useRef(null);
  const sheetRefTop = useRef(null);
  const [content, setContent] = useState();
  const [type, setType] = useState(null);

  const handleOpen = () => {
    sheetRef.current.open();
  };

  const handleOpenTop = () => {
    sheetRefTop.current.open();
  };

  const handleClose = () => {
    sheetRef.current.close();
    sheetRefTop.current.close();
  };

  const showModal = (content, position, kind) => {
    setContent(content);
    setType(kind);
    if (position === true) handleOpenTop();
    else handleOpen();
  };

  return (
    <ModalSheetContext.Provider
      value={{ handleOpen, handleOpenTop, handleClose, showModal }}>
      {children}

      <ActionSheet
        ref={sheetRefTop}
        reverse={true}
        sheetStyle={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignSelf: 'center',
          alignContent: 'center',
          justifyItems: 'center',
          justifySelf: 'center',
          margin: 0,
          background: 'transparent',
        }}
        bgStyle={{ backgroundColor: 'transparent' }}>
        <ModalAreaTop >
          <ModalContent>{content}</ModalContent>
        </ModalAreaTop>
      </ActionSheet>

      <ActionSheet
        ref={sheetRef}
        reverse={false}
        sheetStyle={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignSelf: 'center',
          alignContent: 'center',
          justifyItems: 'center',
          justifySelf: 'center',
          margin: 0,
          background: 'transparent',
        }}
        bgStyle={{ backgroundColor: 'transparent' }}>
        <ModalArea>
          <CloseContainer onClick={handleClose}>
            <CloseButton>x</CloseButton>
          </CloseContainer>
          <ModalContent>{content}</ModalContent>
        </ModalArea>
      </ActionSheet>
    </ModalSheetContext.Provider>
  );
};

export { ModalSheetContext, ModalSheetProvider };
