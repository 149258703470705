import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import useGlobal from '../store';
import FormInput from '../components/FormInput';
import { isValidEmail, isPhoneValid } from '../validators/validators';
import Button from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

const SetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [globalState, globalActions] = useGlobal();
  const [errorEmailMessage, setErrorEmailMessage] = useState('');


  let navigate = useHistory();

  const submitHandler = () => {
    let data = new FormData();
    data.append('spree_user[email]', email);

    axios
      .post('/users/send_set_password_email', data, {
        headers: {
          'Content-Type': 'application/javascript',
          Accept: 'application/javascript',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status == 200) {
          globalActions.updateResponse(res.data.response);
          navigate.push('/application/dashboard');
          props.message(
            'Verifique a sua caixa de entrada para definir a sua palavra-passe.'
          );
          props.messageType(true);
          props.modal(true);
        }
      })
      .catch(function (error) {
        props.message(error.response.data.response.error.message);
        props.messageType(false);
        props.modal(true);
      });
  };

  const navigateToLogin = () => {
    navigate.push('/application/login');
  };

  const submitUnlock = () => {
    if (
      email != '' &&
      errorEmailMessage == ''
    )
      return true;
    else return false;
  };

  return (
    <div className="card register">
      <div className="register__header">
        <div className="card__top-wrapper">
          <Button
            onclick={navigateToLogin}
            className={'button button--back'}
            text={<FontAwesomeIcon icon={faChevronLeft} />}
          />
          <div className="card__title"> {'Receba um novo convite'} </div>
        </div>
      </div>
      <div className="register__content">
        <FormInput
          label={'E-mail'}
          onchange={(e) => {
            setEmail(e.target.value);
            if (e.target.value == '') {
              setErrorEmailMessage('');
            } else if (!isValidEmail(e.target.value)) {
              setErrorEmailMessage('Email Invalido');
            } else {
              setErrorEmailMessage('');
            }
          }}
          value={email}
          type={'email'}
          name={'spree_user[email]'}
          checker={isValidEmail(email)}
          placeholder={'Introduza o seu e-mail'}
          errorText={errorEmailMessage}
          needValidation={true}
        />
      </div>
      <div className="register__button">
        <Button
          onclick={() => (submitUnlock() ? submitHandler() : null)}
          className={
            submitUnlock() ? 'button--primary' : 'button--primary-disabled'
          }
          text={'Enviar'}
        />
      </div>
    </div>
  );
};

export default SetPassword;
