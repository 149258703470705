import React from 'react';
import PropTypes from 'prop-types';
class HelloWorld extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <form action="/users/redeem_code" accept-charset="UTF-8" method="post">
        <input type="hidden" name="authenticity_token" value="" />
        <label for="code"> Código: </label>
        <input type="text" name="code" id="code" />
        <input
          type="submit"
          name="commit"
          value="submit"
          data-disable-with="submit"
        />
      </form>
    );
  }
}

export default HelloWorld;
