import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import Transaction from '../components/Transaction';
import PointsAndPackages from '../components/PointsAndPackages';
import useGlobal from '../store';

const TransactionHistory = () => {
  const [globalState, globalActions] = useGlobal();

  return (
    <div>
      {/* {globalState.user.email != null && (
        <Card layout={<PointsAndPackages />} class={'card'} />
      )} */}
      <Card class={'card'} layout={<Transaction />} />
    </div>
  );
};

export default TransactionHistory;
